<template>
  <div class='flex flex-row justify-center items-center h-screen text-center'>
    <div class='text-center'>
      <img :src='logoURL' class='w-40 inline-block' />
      <div class='mt-16 text-lg leading-10'>
        Please contact the Reach team to complete your setup.<br>
        They need the following information:
        <div class='text-left mx-auto mt-4 border rounded-md p-8 text-base bg-white shadow-md' style='width: 320px'>
          <label class='inline-block w-16 text-gray-600'>Name:</label>
          <div class='inline-block'>{{currentUserInfo.userName}}</div><br>
          <label class='inline-block w-16 text-gray-600'>Email:</label>
          <div class='inline-block'>{{currentUserInfo.email}}</div>
        </div>
        <logout-button class='text-center mt-4 mx-auto border-gray-400 hover:shadow-md' style='width:320px;' />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LogoutButton from '@/components/LogoutButton.vue'

export default {
  name: 'NewUser',
  components: {
    LogoutButton,
  },
  data () {
    return {
      logoURL: process.env.VUE_APP_CLIENT_BASE_URL + '/logo_reach_horizontal.svg'
    }
  },
  computed: {
    ...mapState('users', [
      'currentUserInfo'
    ])
  },
}
</script>
